import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

class PrivateRoute extends Component {
  userIsAuthenticatedSameGame(gameUrlKey) {
    // return false;
    return this.props.auth.user && this.props.auth.user.gameUrl === gameUrlKey;
  }

  render() {
    var { component: Component, ...rest } = this.props;

    const splitUrl = this.props.location.pathname.split("/");
    const gameUrlKey = splitUrl[1];

    return (
      <Route
        {...rest}
        render={props =>
          this.userIsAuthenticatedSameGame(gameUrlKey) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/" + gameUrlKey,
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  null
)(PrivateRoute);
