import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { Form, Button, Message } from "semantic-ui-react";

import GameHomeContainer from "components/App/GameHomeContainer";
import { forgotPassword } from "actions/authActions";
import { clearFormError } from "actions/messageActions";

class GameLoginForgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.props.clearFormError();
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.forgotPassword(
      window.location.hostname,
      this.props.match.params.gameurlkey,
      this.state.login
    );
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.props;

    const formError =
      typeof this.props.errors === "object" &&
      Object.keys(this.props.errors).length !== 0;

    return (
      <GameHomeContainer>
        <div className="game-home-login-forgot">
          {formError ? (
            <Message error>
              <Message.List>
                {_.map(errors, function(error, key) {
                  return <Message.Item key={key}>{error}</Message.Item>;
                })}
              </Message.List>
            </Message>
          ) : null}
          <Form onSubmit={this.onSubmit}>
            <Form.Input
              name="login"
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Login"
              value={this.state.login}
              onChange={this.onChange}
              autoComplete="off"
              error={!!errors.login}
            />
            <Button fluid size="large">
              Ré-initialiser mot de passe
            </Button>
          </Form>
        </div>
      </GameHomeContainer>
    );
  }
}

GameLoginForgot.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  clearFormError: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  game: state.game,
  errors: state.formErrors
});

export default connect(
  mapStateToProps,
  { forgotPassword, clearFormError }
)(GameLoginForgot);
