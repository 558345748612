import { RENDER_INPUT, CLEAR_INPUT } from "./types";

export const renderInput = step => dispatch => {
  dispatch({
    type: RENDER_INPUT,
    payload: step
  });
};

export const clearInput = data => dispatch => {
  dispatch({
    type: CLEAR_INPUT,
    payload: data
  });
};
