import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/es/map";
import "core-js/es/set";
import "raf/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import './i18n';

// import  css
import "../node_modules/video-react/dist/video-react.css";

// Do not use "import App from ..." as hoisting will screw up styling
const App = require("./pages/App").default;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("app")
);
