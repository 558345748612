import EventEmitter2 from "eventemitter2";

export const EVENT_SCROLL_TOBOTTOM = "EVENT_SCROLL_TOBOTTOM";
export const EVENT_NEXT_STEP = "EVENT_NEXT_STEP";

class EventBus extends EventEmitter2 {
  constructor() {
    super({
      wildcard: true,
      maxListeners: 100
    });
  }
}

EventBus.default = new EventBus();

export default EventBus.default;
