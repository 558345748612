import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Header, Icon } from "semantic-ui-react";

import LogoutButton from "components/Common/LogoutButton";
import BackButton from "components/Common/BackButton";
import ResetButton from "components/Common/ResetButton";

class ChatHeader extends Component {
  render() {
    const headerStyle = {
      textTransform: "uppercase",
      flexGrow: "1",
      margin: "0",
      fontWeight: "400",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "'Open Sans', sans-serif",
    };

    const styleButton = {
      backgroundColor: "transparent",
      border: 0,
      padding: "5px",
    };

    return (
      <div
        className="chat-header"
        style={this.props.game.configuration.styleChatHeader}
      >
        {!this.props.game.configuration.homeUrl && (
          <BackButton
            href={"/" + this.props.game.urlkey + "/resume"}
            iconName="home"
          />
        )}
        {this.props.game.configuration.homeUrl && (
          <a href={this.props.game.configuration.homeUrl}>
            <Button icon size="huge" style={styleButton}>
              <Icon name="home" />
            </Button>
          </a>
        )}
        <Header as="h3" style={headerStyle}>
          {this.props.story.name}
        </Header>
        <ResetButton />
        <LogoutButton />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  game: state.game,
  story: state.story,
});

export default connect(mapStateToProps, null)(ChatHeader);
