import {
  FETCH_GAME_BEGIN,
  FETCH_GAME_SUCCESS,
  FETCH_GAME_FAILURE,
  CLEAR_GAME,
  SET_CURRENT_USER,
  NEW_STEPS
} from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case NEW_STEPS:
      return {
        ...state,
        ...action.payload.player
      };
    case FETCH_GAME_BEGIN:
      return initialState;
    case FETCH_GAME_SUCCESS:
      return action.payload.player || {};
    case FETCH_GAME_FAILURE:
      return initialState;
    case SET_CURRENT_USER:
    case CLEAR_GAME:
      return initialState;
    default:
      return state;
  }
}
