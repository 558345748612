import React, { Component } from "react";
import PropTypes from "prop-types";
import renderHTML from "react-render-html";

import _ from "lodash";

import SubmitButton from "./SubmitButton";

class OptionsMultipleField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: []
    };

    this.renderOption = this.renderOption.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(value) {
    let { data } = this.state;
    if (data.includes(value)) {
      // The choice was already selected, remove it from the list
      _.remove(data, function(n) {
        return n === value;
      });
    } else {
      // Add the choice to the list
      data.push(value);
    }
    this.setState({ data: data });
  }

  onSubmit() {
    this.props.onSubmit(this.state.data);
  }

  renderOption(option) {
    const { value, label, html } = option;

    let style = _.merge({}, this.props.styleOptionElement);
    if (this.state.data.includes(value)) {
      _.merge(style, this.props.styleOptionElementSelected);
    }

    return (
      <li
        key={value}
        className="chat-input-option"
        style={this.props.styleOption}
      >
        <button
          style={style}
          className="chat-input-option-element"
          value={value}
          onClick={() => this.onChange(value)}
        >
          {html ? renderHTML(label) : label}
        </button>
      </li>
    );
  }

  render() {
    var classes_options = "chat-input-options";
    var submit = "";

    if (this.state.data.length > 0) {
      classes_options += " chat-input-options-submit";
      submit = <SubmitButton onSubmit={this.onSubmit} />;
    }
    return (
      <div className="chat-input-options-container">
        <ul className={classes_options}>
          {_.map(this.props.options, this.renderOption)}
        </ul>
        {submit}
      </div>
    );
  }
}

OptionsMultipleField.propTypes = {
  options: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  styleOption: PropTypes.object.isRequired,
  styleOptionElement: PropTypes.object.isRequired,
  styleOptionElementSelected: PropTypes.object.isRequired
};

export default OptionsMultipleField;
