import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './message.css';

class Avatar extends Component {
  render() {
    return (
      <div
        className="chat-message-avatar-container"
        style={this.props.styleContainer}
      >
        <img
          className="chat-message-avatar"
          src={this.props.src}
          alt={this.props.alt}
          style={this.props.styleImage}
        />
      </div>
    );
  }
}

Avatar.propTypes = {
  styleContainer: PropTypes.object,
  styleImage: PropTypes.object,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

Avatar.defaultProps = {
  styleContainer: {},
  styleImage: {}
};

export default Avatar;
