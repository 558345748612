import React from 'react';
import styled from 'styled-components';
import { loading } from '../../common/animations';

const Dot = styled.span`
animation: ${loading} 1.4s infinite both;
animation-delay: ${props => props.delay} ;
`;

const Loading = () => {

  return (
    <span>
         <Dot delay="0s">.</Dot>
         <Dot delay=".2s">.</Dot>
         <Dot delay=".4s">.</Dot>
    </span>
  )
};

export default Loading;
