import React, { Component } from "react";
import { connect } from "react-redux";
import nl2br from "react-nl2br";

import { Header, Modal, Button } from "semantic-ui-react";

import Login from "components/App/Login";
import GameHomeContainer from "components/App/GameHomeContainer";

class GameHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.redirectIfUserAuthenticatedSameGame();

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  componentDidUpdate() {
    this.redirectIfUserAuthenticatedSameGame();
  }

  redirectIfUserAuthenticatedSameGame() {
    if (
      this.props.auth.user &&
      this.props.auth.user.gameUrl === this.props.match.params.gameurlkey
    ) {
      this.props.history.push(
        "/" + this.props.match.params.gameurlkey + "/resume"
      );
    }
  }

  renderLogin() {
    return (
      <Login
        gameId={this.props.game.id}
        gameURL={this.props.match.params.gameurlkey}
      />
    );
  }

  renderContinue() {
    // When anonymous game, the loading of the user is done in <Game>
    return (
      <Button
        fluid
        size="large"
        href={"/" + this.props.match.params.gameurlkey + "/resume"}
      >
        Continuer
      </Button>
    );
  }

  renderDescModal() {
    const { open } = this.state;
    const mayDisplayModal = !!this.props.game.configuration.desc.second;

    return (
      <div>
        <p>{nl2br(this.props.game.configuration.desc.main)}</p>
        { mayDisplayModal && <a href="#seemore" onClick={this.showModal}>Voir plus</a>}
        <Modal open={open} onClose={this.closeModal} closeIcon>
          <Modal.Content scrolling>
            <Modal.Description className="game-home-desc-modal">
              {nl2br(this.props.game.configuration.desc.second)}
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }

  render() {
    const mobile = this.props.mobile;

    const action = this.props.game.configuration.hasLogger
      ? this.renderLogin()
      : this.renderContinue();

    const styleHeader = {
      fontSize: mobile ? "3rem" : "4rem",
      marginTop: mobile ? "80px" : "150px"
    };
    const styleDesc = {
      fontSize: mobile ? "1rem" : "1.5rem"
    };
    const styleAction = {
      marginBottom: mobile ? "20px" : "40px"
    };

    return (
      <GameHomeContainer>
        <Header
          className="game-home-header"
          as="h1"
          textAlign="center"
          style={styleHeader}
        >
          {this.props.game.configuration.title}
        </Header>
        <div className="game-home-desc" style={styleDesc}>
          {mobile ? (
            this.renderDescModal()
          ) : (
            <p>
              {nl2br(this.props.game.configuration.desc.main)}
              <br />
              {nl2br(this.props.game.configuration.desc.second)}
            </p>
          )}
        </div>
        <div className="game-home-action" style={styleAction}>
          {action}
        </div>
      </GameHomeContainer>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  game: state.game,
  mobile: state.configuration.mobile
});

export default connect(
  mapStateToProps,
  null
)(GameHome);
