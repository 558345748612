import i18next from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      answers: "Answers",
      difficulty: "difficulty",
      make_your_choice: "Make your choice",
      make_your_choices: "Make your choices",
      other_games: "Other games",
      play: "play",
      send: "Send",
      to_get_started: "to get started",
    },
  },

  fr: {
    translation: {
      answers: "Cliquez ici",
      difficulty: "difficulté",
      make_your_choice: "Faites votre choix",
      make_your_choices: "Faites vos choix",
      other_games: "Autres jeux",
      play: "jouer",
      send: "Envoyer",
      to_get_started: "pour commencer",
    },
  },
};

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18next;
