import { SET_FORM_ERRORS, CLEAR_FORM_ERRORS } from "actions/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_FORM_ERRORS:
      return {
        ...action.payload
      };
    case CLEAR_FORM_ERRORS:
      return {
        ...action.payload
      };
    default:
      return state;
  }
}
