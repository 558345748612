import _ from "lodash";
import {
  FETCH_STORY_BEGIN,
  FETCH_STORY_SUCCESS,
  FETCH_STORY_FAILURE,
  CLEAR_STORY,
  NEW_STEPS,
  RENDER_STEP,
  FETCH_GAME_BEGIN,
  FETCH_GAME_SUCCESS,
  FETCH_GAME_FAILURE,
  CLEAR_GAME,
  RENDER_INPUT,
  CLEAR_INPUT,
} from "actions/types";

const initialState = {
  id: undefined,
  loading: true,
  steps: {},
  renderedSteps: [],
  input: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_STORY_BEGIN:
      return initialState;
    case FETCH_STORY_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload.story,
        steps: _.keyBy(action.payload.story.steps, "messageId"),
        renderedSteps: [],
        input: {}
      };
    case FETCH_STORY_FAILURE:
      return {
        id: undefined,
        loading: false,
        steps: {},
        renderedSteps: [],
        input: {}
      };
    case CLEAR_STORY:
      return { ...initialState, loading: false };
    case NEW_STEPS:
      _.forEach(action.payload.conversation, function(step) {
        // Replace the rendered step if it already exists
        var index = _.findIndex(state.renderedSteps, {
          messageId: step.messageId
        });
        if (index >= 0) {
          state.renderedSteps.splice(index, 1, step);
        }
      });

      return {
        ...state,
        steps: {
          ...state.steps,
          ..._.keyBy(action.payload.conversation, "messageId")
        },
        renderedSteps: [...state.renderedSteps]
      };
    case RENDER_STEP:
      // Replace the rendered step if it already exists
      var index = _.findIndex(state.renderedSteps, {
        messageId: action.payload.messageId
      });
      if (index >= 0) {
        state.renderedSteps.splice(index, 1, action.payload);
      }
      // Else add the step to the rendered step
      else {
        state.renderedSteps.push(action.payload);
      }

      // Replace the step, just in case it wasnt there (user step for example)
      var stepToReplace = {};
      stepToReplace[action.payload.messageId] = action.payload;

      return {
        ...state,
        steps: { ...state.steps, ...stepToReplace },
        renderedSteps: [...state.renderedSteps]
      };   

    case FETCH_GAME_BEGIN:
    case FETCH_GAME_SUCCESS:
    case FETCH_GAME_FAILURE:
    case CLEAR_GAME:
      return initialState;
    case RENDER_INPUT:
      return {
        ...state,
        input: action.payload
      };
    case CLEAR_INPUT:
      return {
        ...state,
        input: {}
      };
    default:
      return state;
  }
}
