import {
  FETCH_GAME_BEGIN,
  FETCH_GAME_SUCCESS,
  FETCH_GAME_FAILURE,
  CLEAR_GAME,
  SET_CURRENT_USER
} from "actions/types";

const initialState = {
  id: undefined,
  loading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_GAME_BEGIN:
      return initialState;
    case FETCH_GAME_SUCCESS:
      if (action.payload.game === false) {
        action.payload.game = { id: false };
      }

      action.payload.game.loading = false;

      return {
        ...action.payload.game
      };
    case FETCH_GAME_FAILURE:
      return {
        ...initialState,
        loading: false
      };
    case SET_CURRENT_USER:
    case CLEAR_GAME:
      return initialState;
    default:
      return state;
  }
}
