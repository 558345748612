import React, { Component } from "react";
import { connect } from "react-redux";

import { Icon, Button } from "semantic-ui-react";

import { resetStory } from "actions/gameActions";

class ResetButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();

    this.props.resetStory(
      this.props.game.urlkey,
      this.props.story.id,
      this.props.story.startStepId,
      null,
      null
    );
  }

  render() {
    const styleButton = {
      backgroundColor: "transparent",
      border: 0,
      padding: "5px"
    };

    return (
      <Button icon size="huge" style={styleButton} onClick={this.onClick}>
        <Icon name="redo" />
      </Button>
    );
  }
}

const mapStateToProps = state => ({
  game: state.game,
  story: state.story
});

export default connect(
  mapStateToProps,
  { resetStory }
)(ResetButton);
