export const FETCH_GAME_BEGIN = "FETCH_GAME_BEGIN";
export const FETCH_GAME_SUCCESS = "FETCH_GAME_SUCCESS";
export const FETCH_GAME_FAILURE = "FETCH_GAME_FAILURE";
export const CLEAR_GAME = "CLEAR_GAME";

export const FETCH_STORY_BEGIN = "FETCH_STORY_BEGIN";
export const FETCH_STORY_SUCCESS = "FETCH_STORY_SUCCESS";
export const FETCH_STORY_FAILURE = "FETCH_STORY_FAILURE";
export const CLEAR_STORY = "CLEAR_STORY";

export const NEW_STEPS = "NEW_STEPS";

export const RENDER_STEP = "RENDER_STEP";

export const RENDER_INPUT = "RENDER_INPUT";
export const CLEAR_INPUT = "CLEAR_INPUT";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_FORM_ERRORS = "SET_FORM_ERRORS";
export const CLEAR_FORM_ERRORS = "CLEAR_FORM_ERRORS";

export const SET_MOBILE = "SET_MOBILE";
