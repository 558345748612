import axios from "axios";
import setAuthToken from "util/setAuthToken";
import jwt_decode from "jwt-decode";
import customHistory from "../history";

import { SET_CURRENT_USER, LOGOUT_USER } from "./types";
import { handleMessage, handleError } from "./messageActions";

// Login - Get User Token
export const loginUser = (gameId, login, password) => dispatch => {
  axios
    .post("/api/user/login", {
      gameId: gameId,
      login: login,
      password: password
    })
    .then(res => {
      // Save to localStorage
      const { token } = res.data;
      // Log the user
      dispatch(loginAction(token));
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);

  // Set current user to {} which will set isAuthenticated to false
  dispatch({
    type: LOGOUT_USER
  });
};

// Login Anonymous - Get User Token
export const loginAnonymous = gameId => dispatch => {
  axios
    .post("/api/user/login-anonymous", {
      gameId: gameId
    })
    .then(res => {
      // Save to localStorage
      const { token } = res.data;
      // Log the user
      dispatch(loginAction(token));
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const loginAction = token => {
  // Set token to localStorage
  localStorage.setItem("jwtToken", token);
  // Set token to Auth header
  setAuthToken(token);
  // Decode token to get user data
  const decoded = jwt_decode(token);

  // Set current user
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Set logged in user
export const setCurrentUser = user => dispatch => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: user
  });
};

// Forgot password
export const forgotPassword = (host, gameURL, login) => dispatch => {
  axios
    .post("/api/user/forgot", {
      host: host,
      gameURL: gameURL,
      login: login
    })
    .then(res => {
      dispatch(
        handleMessage(  
          "Un email vous a été envoyé avec les instructions à suivre"
        )
      );
      customHistory.push("/" + gameURL);
    })
    .catch(err => {
      dispatch(
        handleError(err)
      );
      customHistory.push("/" + gameURL);
    });
};

// user registration
export const register = (host, gameURL, user) => dispatch => {
  axios
    .post("/api/user/register", {
      host: host,
      gameURL: gameURL,
      user: user
    })
    .then(res => {
      dispatch(
        handleMessage(
          "Un email vous a été envoyé avec les instructions à suivre"
        )
      );
      customHistory.push("/" + gameURL);
    })
    .catch(err => {
      dispatch(
        handleError(err)
      );
      // customHistory.push("/" + gameURL);
    });
};

// user registration
export const activateAccount = (gameURL, token) => dispatch => {
  axios
    .post("/api/user/activate", {
      gameURL: gameURL,
      token: token
    })
    .then(res => {
      dispatch(
        handleMessage(
          "Votre compte a été activé avec succès"
        )
      );
      customHistory.push("/" + gameURL);
    })
    .catch(err => {
      const message = (err.response && err.response.data && err.response.data.message) 
      ?  err.response.data.message : "Un problème d'origine inconnu est survenu lors de l'activation, veuillez réessayer plus tard"

      dispatch(
        handleMessage(
          message
        )
      );
      customHistory.push("/" + gameURL);
    });
};

// Reset password
export const resetPassword = (
  gameURL,
  token,
  password,
  password2
) => dispatch => {
  axios
    .post("/api/user/reset", {
      token: token,
      password: password,
      password2: password2
    })
    .then(res => {
      dispatch(handleMessage("Votre mot de passe a été mis à jour"));
      customHistory.push("/" + gameURL);
    })
    .catch(err => {
      dispatch(
        handleError(err)
      );
    });
};
