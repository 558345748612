import {
  NEW_STEPS,
  FETCH_GAME_BEGIN,
  FETCH_GAME_SUCCESS,
  FETCH_GAME_FAILURE,
  CLEAR_GAME,
  FETCH_STORY_SUCCESS
} from '../actions/types';

import _ from 'lodash';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case NEW_STEPS:
      return _.merge(state, action.payload.matches);
    case FETCH_GAME_BEGIN:
      return initialState;
    case FETCH_GAME_SUCCESS:
      return action.payload.matches || {};
    case FETCH_GAME_FAILURE:
      return initialState;
    case CLEAR_GAME:
      return initialState;
    case FETCH_STORY_SUCCESS:
      return _.merge(state, action.payload.matches);
    default:
      return state;
  }
}
