import {
  FETCH_GAME_BEGIN,
  FETCH_GAME_SUCCESS,
  FETCH_GAME_FAILURE,
  CLEAR_GAME
} from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_GAME_BEGIN:
      return initialState;
    case FETCH_GAME_SUCCESS:
      return action.payload.stories || {};
    case FETCH_GAME_FAILURE:
      return initialState;
    case CLEAR_GAME:
      return initialState;
    default:
      return state;
  }
}
