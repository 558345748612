import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Button, Message } from "semantic-ui-react";

import GameHomeContainer from "components/App/GameHomeContainer";
import { register } from "actions/authActions";
import { clearFormError } from "actions/messageActions";

import "./gameRegister.css";

class GameRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    };

    // this.props.clearFormError();
  }

  onSubmit = e => {
    e.preventDefault();

    this.props.register(
      window.location.hostname,
      this.props.match.params.gameurlkey,
      this.state
    );
  }


  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }


  render() {
    const { errors } = this.props;
    const entries = Object.entries(errors);

    // const formError =
    //   typeof this.props.errors === "object" &&
    //   Object.keys(this.props.errors).length !== 0;

    return (
      <GameHomeContainer>
        <div className="game-register">
          {entries.length > 0 ? (
            <Message error>
              <Message.List>              
                
                {
                entries.map(([key, error]) => <Message.Item key={key}>{error}</Message.Item>)
                             
                }
              </Message.List>
            </Message>
          ) : null}
          <Form onSubmit={this.onSubmit} noValidate>
            <Form.Input
              name="firstName"
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Prénom"
              value={this.state.firstName}
              onChange={this.onChange}
              autoComplete="off"
              error={!!errors.firstName}
            />
            <Form.Input
              name="lastName"
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Nom"
              value={this.state.lastName}
              onChange={this.onChange}
              autoComplete="off"
              error={!!errors.lastName}
            />
            <Form.Input
              type="email"
              name="email"
              fluid
              icon="mail"
              iconPosition="left"
              placeholder="Email"
              value={this.state.email}
              onChange={this.onChange}
              autoComplete="off"
              error={!!errors.email}
            />
            <Form.Input
              type="password"
              name="password"
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Mot de passe"
              value={this.state.password}
              onChange={this.onChange}
              autoComplete="off"
              error={!!errors.password}
            />
            <Button fluid size="large">
              Je m'inscris !
            </Button>
          </Form>
        </div>
      </GameHomeContainer>
    );
  }
}

GameRegister.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  game: state.game,
  errors: state.formErrors
});

export default connect(
  mapStateToProps,
  { register, clearFormError }
)(GameRegister);
