import axios from "axios";
import customHistory from "../history";

import {
  FETCH_GAME_BEGIN,
  FETCH_GAME_SUCCESS,
  FETCH_GAME_FAILURE,
  FETCH_STORY_BEGIN,
  FETCH_STORY_SUCCESS,
  FETCH_STORY_FAILURE,
  CLEAR_STORY,
  NEW_STEPS,
  RENDER_STEP
} from "./types";
import { handleError } from "./messageActions";

export const getGame = gameUrlKey => dispatch => {
  axios
    .get(`/api/game/${gameUrlKey}`)
    .then(dispatch({ type: FETCH_GAME_BEGIN }))
    .then(res => {
      dispatch({
        type: FETCH_GAME_SUCCESS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: FETCH_GAME_FAILURE
      });
      dispatch(handleError(err));
      customHistory.push("/");
    });
};

export const getStory = (gameUrlKey, storyId) => dispatch => {
  axios
    .get(`/api/game/${gameUrlKey}/${storyId}`)
    .then(dispatch({ type: FETCH_STORY_BEGIN }))
    .then(res => {
      dispatch({
        type: FETCH_STORY_SUCCESS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: FETCH_STORY_FAILURE,
        payload: err.response
      });
      dispatch(handleError(err));
      customHistory.push("/" + gameUrlKey + "/resume");
    });
};

export const sendUserMessage = (
  gameUrlKey,
  storyId,
  stepId,
  matchId,
  messageId,
  data
) => dispatch => {
  axios
    .post(`/api/game/message`, {
      gameUrlKey: gameUrlKey,
      storyId: storyId,
      stepId: stepId,
      matchId: matchId,
      messageId: messageId,
      data: data
    })
    .then(res => {
      if (res.data.action && res.data.action === "REFRESH") {
        dispatch({
          type: CLEAR_STORY
        });
      } else {
        dispatch({
          type: NEW_STEPS,
          payload: res.data
        });
      }
    })
    .catch(err => {
      dispatch({
        type: CLEAR_STORY
      });
      dispatch(handleError(err));
      customHistory.push("/" + data.gameUrlKey + "/resume");
    });
  return;
};

export const resetStory = (
  gameUrl,
  storyId,
  targetStep,
  sourceStep,
  sourceValue
) => dispatch => {
  axios
    .post(`/api/game/reset`, {
      gameUrl: gameUrl,
      storyId: storyId,
      targetStep: targetStep,
      sourceStep: sourceStep,
      sourceValue: sourceValue
    })
    .then(res => {
      dispatch({
        type: CLEAR_STORY
      });
    })
    .catch(err => {
      dispatch({
        type: CLEAR_STORY
      });
      dispatch(handleError(err));
      customHistory.push("/" + gameUrl + "/resume");
    });
  return;
};

export const renderStep = step => dispatch => {
  dispatch({
    type: RENDER_STEP,
    payload: step
  });
};

export const clearStory = () => dispatch => {
  dispatch({
    type: CLEAR_STORY
  });
};
