import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

const initialState = {};

const middlewareApp = [thunk];

const middleware = [
  applyMiddleware(...middlewareApp),
  ...(window.__REDUX_DEVTOOLS_EXTENSION__
    ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
    : [])
];

const store = createStore(reducers, initialState, compose(...middleware));

export default store;
