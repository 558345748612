import { CLEAR_MESSAGE, SET_MESSAGE } from "actions/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...action.payload
      };
    case CLEAR_MESSAGE:
      return initialState;
    default:
      return state;
  }
}
