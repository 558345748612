import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { Form, Button, Message } from "semantic-ui-react";

import GameHomeContainer from "components/App/GameHomeContainer";
import { activateAccount } from "actions/authActions";

import "./gameAccountActivation.css";

class GameAccountActivation extends Component {

  onSubmit = (e) => {
    e.preventDefault();

    this.props.activateAccount(
      this.props.match.params.gameurlkey,
      this.props.match.params.token
    );
  };

  render() {
    const { errors } = this.props;

    const formError =
      typeof this.props.errors === "object" &&
      Object.keys(this.props.errors).length !== 0;

    return (
      <GameHomeContainer>
        <div className="game-activate-account">
          {formError ? (
            <Message error>
              <Message.List>
                {_.map(errors, function(error, key) {
                  return <Message.Item key={key}>{error}</Message.Item>;
                })}
              </Message.List>
            </Message>
          ) : null}
          <Form onSubmit={this.onSubmit}>
            <Button fluid size="large">
              Activer mon compte
            </Button>
          </Form>
        </div>
      </GameHomeContainer>
    );
  }
}

GameAccountActivation.propTypes = {
  activateAccount: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  game: state.game,
  errors: state.formErrors
});

export default connect(
  mapStateToProps,
  { activateAccount }
)(GameAccountActivation);
