import React from "react";
import PropTypes from "prop-types";

function Billboard(props) {
    return (
        <div className="billboard">
            <a href={props.baseUrl + props.story.id}>
                <img style={{ maxHeight: "300px", objectFit: "cover" }} alt={props.story.name} className="ui bordered fluid rounded image" src={`/images/${props.game.urlkey}/${props.story.code}-thumb.jpg`} />
                <div className="billboard-headline">
                <h2>{props.story.name}</h2>
            </div>
            </a>
            {/* <Image src={`/images/pno/${props.story.code}-thumb.jpg`} fluid rounded bordered as={Link} to={props.baseUrl + props.story.id}
                style={{ maxHeight: "300px", objectFit: "contains" }} /> */}

        </div>
    );
}

Billboard.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    story: PropTypes.object.isRequired,
    game: PropTypes.object.isRequired
};

export default Billboard;
