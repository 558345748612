import React, { Component } from "react";
import PropTypes from "prop-types";

import { Icon, Button } from "semantic-ui-react";

class SubmitButton extends Component {
  render() {
    return (
      <Button icon className="chat-input-submit" onClick={this.props.onSubmit}>
        <Icon name="send" />
      </Button>
    );
  }
}

SubmitButton.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SubmitButton;
