import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import store from "../../store";
import jwt_decode from "jwt-decode";

import { Dimmer, Loader } from "semantic-ui-react";

import { getGame } from "actions/gameActions";
import { setCurrentUser, logoutUser } from "actions/authActions";
import setAuthToken from "util/setAuthToken";

import Chat from "./play/Chat";
import GameHome from "./GameHome";
import GameResume from "./resume/GameResume";
import GameDetail from "./detail/GameDetail";
import GameLoginForgot from "./forgot/GameLoginForgot";
import GameLoginReset from "./reset/GameLoginReset";
import GameRegister from "./register/GameRegister";
import PageNotFound from "pages/PageNotFound";

import { Switch, Route } from "react-router-dom";

import PrivateRoute from "components/App/PrivateRoute";
import LoginAnonymous from "components/App/LoginAnonymous";
import GameAccountActivation from "pages/game/activate/GameAccountActivation";

// Check for token
if (localStorage.jwtToken) {
  // Decode token and get user info and exp
  var decoded = {};
  try {
    decoded = jwt_decode(localStorage.jwtToken);

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(logoutUser());
    } else {
      // Set auth token header auth
      setAuthToken(localStorage.jwtToken);

      // Set user and isAuthenticated
      store.dispatch(setCurrentUser(decoded));
    }
  } catch (error) {
    // invalid token format
    // Logout user
    store.dispatch(logoutUser());
  }
}

class Game extends Component {
  constructor(props) {
    super(props);

    this.props.getGame(this.props.match.params.gameurlkey);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.game.id === undefined &&
      this.props.game.id !== prevProps.game.id
    ) {
      this.props.getGame(this.props.match.params.gameurlkey);
    }
  }

  // Same function as the one in <PrivateRoute>
  userIsAuthenticatedSameGame() {
    return (
      this.props.auth.user &&
      this.props.auth.user.gameUrl === this.props.game.urlkey
    );
  }

  render() {
    let content;
    let dimmer = false;

    if (this.props.game.id === undefined || this.props.game.loading) {
      content = (
        <div
          style={{ height: "100%", width: "100%", backgroundColor: "white" }}
        />
      );
      dimmer = true;
    } else if (this.props.game.id === false) {
      content = <PageNotFound />;
    } else if (
      // If the user is not logged AND the game is an anonymous game
      // We display the loading screen and <LoginAnonymous> to load the user detail
      !this.props.game.configuration.hasLogger &&
      !this.userIsAuthenticatedSameGame()
    ) {
      content = (
        <div
          style={{ height: "100%", width: "100%", backgroundColor: "white" }}
        >
          <LoginAnonymous gameId={this.props.game.id} />
        </div>
      );
      dimmer = true;
    } else {
      content = (
        <Switch>
          <PrivateRoute path="/:gameurlkey/play/:storyid" component={Chat} />
          <PrivateRoute path="/:gameurlkey/detail/:storyid" component={GameDetail} />
          <PrivateRoute path="/:gameurlkey/resume" component={GameResume} />
          <Route path="/:gameurlkey/register" component={GameRegister} />
          <Route path="/:gameurlkey/activate/:token" component={GameAccountActivation} />
          <Route path="/:gameurlkey/forgot" component={GameLoginForgot} />
          <Route path="/:gameurlkey/reset/:token" component={GameLoginReset} />
          <Route component={GameHome} />
        </Switch>
      );
    }

    return (
      <Dimmer.Dimmable dimmed={dimmer}>
        <Dimmer active={dimmer}>
          <Loader>Loading</Loader>
        </Dimmer>
        {content}
      </Dimmer.Dimmable>
    );
  }
}

Game.propTypes = {
  getGame: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  game: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
};

const mapStateToProps = state => ({
  auth: state.auth,
  game: state.game
});

export default connect(
  mapStateToProps,
  {
    getGame,
    logoutUser
  }
)(Game);
