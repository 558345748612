import React, { Component } from "react";
import PropTypes from "prop-types";
import renderHTML from "react-render-html";

import Loading from "components/Common/Loading";

class MessageText extends Component {
  render() {
    return (
      <div className="chat-message-content" style={this.props.style}>
        <div className="chat-message-text" style={this.props.styleText}>
          {this.props.loading ? (
            <Loading />
          ) : this.props.html ? (
            renderHTML(this.props.content)
          ) : (
            this.props.content
          )}
        </div>
      </div>
    );
  }
}

MessageText.propTypes = {
  loading: PropTypes.bool,
  html: PropTypes.bool,
  content: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  styleText: PropTypes.object.isRequired
};

export default MessageText;
