import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Icon, Button } from 'semantic-ui-react';

class BackButton extends Component {
  render() {
    const styleButton = {
      backgroundColor: 'transparent',
      border: 0,
      padding: "5px"
    };

    return (
      <Button
        as={Link}
        icon
        size="huge"
        style={styleButton}
        to={this.props.href}
      >
        <Icon name={this.props.iconName} />
      </Button>
    );
  }
}

BackButton.propTypes = {
  href: PropTypes.string.isRequired
};

export default BackButton;
