import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { Form, Button, Message } from "semantic-ui-react";

import GameHomeContainer from "components/App/GameHomeContainer";

import { resetPassword } from "actions/authActions";
import { clearFormError } from "actions/messageActions";

class GameLoginReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password2: ""
    };

    // this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.props.clearFormError();
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.resetPassword(
      this.props.match.params.gameurlkey,
      this.props.match.params.token,
      this.state.password,
      this.state.password2
    );
  }


  onChange = (e) => this.setState({
    [e.target.name]: e.target.value
  });


  render() {
    const { errors } = this.props;

    const formError =
      typeof this.props.errors === "object" &&
      Object.keys(this.props.errors).length !== 0;

    return (
      <GameHomeContainer>
        <div className="game-home-login-reset">
          {formError ? (
            <Message error>
              <Message.List>
                {_.map(errors, function (error, key) {
                  return <Message.Item key={key}>{error}</Message.Item>;
                })}
              </Message.List>
            </Message>
          ) : null}
          <Form onSubmit={this.onSubmit}>
            <Form.Input
              name="password"
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={this.state.password}
              onChange={this.onChange}
              error={!!errors.password}
            />
            <Form.Input
              name="password2"
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Confirm password"
              type="password"
              value={this.state.password2}
              onChange={this.onChange}
              error={!!errors.password2}
            />
            <Button fluid size="large">
              Ré-initialiser mot de passe
            </Button>
          </Form>
        </div>
      </GameHomeContainer>
    );
  }
}

GameLoginReset.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  clearFormError: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  game: state.game,
  errors: state.formErrors
});

export default connect(
  mapStateToProps,
  { resetPassword, clearFormError }
)(GameLoginReset);
