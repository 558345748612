import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Header, Grid, Segment } from "semantic-ui-react";

class PageNotFound extends Component {
  render() {
    return (
      <Segment inverted style={{ padding: "0px" }}>
        <Grid
          inverted
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column>
            <Container text>
              <Header
                as="h1"
                content="Page not found"
                inverted
                style={{
                  fontSize: this.props.mobile ? "2em" : "5em",
                  fontWeight: "normal"
                }}
              />
            </Container>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({
  mobile: state.configuration.mobile
});

export default connect(
  mapStateToProps,
  null
)(PageNotFound);
