import React, { Component } from "react";
import PropTypes from "prop-types";
import { Player, BigPlayButton, LoadingSpinner } from "video-react";

import _ from "lodash";

import MessageText from "./MessageText";

class MessageVideo extends Component {
  render() {
    // Set empty poster by default
    const { src, poster = "" } = this.props.content;

    var style = _.merge({}, this.props.style);
    _.merge(style, this.props.styleVideo);

    if (this.props.loading) {
      return <MessageText {...this.props} content="" />;
    }

    // We set chat-message-video to be able to add "flex-grow:1" to the video
    return (
      <div className="chat-message-content chat-message-video" style={style}>
        <Player src={src} poster={poster}>
          <LoadingSpinner />
          <BigPlayButton position="center" />
        </Player>
      </div>
    );
  }
}

MessageVideo.propTypes = {
  loading: PropTypes.bool,
  content: PropTypes.shape({
    src: PropTypes.string.isRequired,
    poster: PropTypes.string
  }),

  style: PropTypes.object.isRequired,
  styleText: PropTypes.object.isRequired,
  styleVideo: PropTypes.object.isRequired
};

export default MessageVideo;
