import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import nanoid from "nanoid";
import _ from "lodash";

import ChatHeader from "components/Chat/ChatHeader";
import ChatContent from "components/Chat/ChatContent";
import ChatFooter from "components/Chat/ChatFooter";
import GameContainer from "components/App/GameContainer";

import { Loader } from "semantic-ui-react";

import EventBus, { EVENT_NEXT_STEP } from "util/EventBus";

import {
  getStory,
  sendUserMessage,
  renderStep,
} from "actions/gameActions";
import { renderInput, clearInput } from "actions/inputActions";

import "./chat.css";

class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: null
    };

    this.triggerNextStep = this.triggerNextStep.bind(this);

    this.props.getStory(
      this.props.game.urlkey,
      this.props.match.params.storyid
    );

    EventBus.on(EVENT_NEXT_STEP, this.triggerNextStep);
  }

  componentWillUnmount() {
    EventBus.off(EVENT_NEXT_STEP, this.triggerNextStep);
  }

  componentDidUpdate(prevProps) {
    // If these conditions are met, we just load a new story. We need to display the first step, which will trigger the bot
    if (
      Object.keys(this.props.story.steps).length > 0 &&
      Object.keys(prevProps.story.steps).length <= 0
    ) {
      // V1 : when resuming a story, used to display steps one by one which triggers exception due too too many calls to componentDidUpdate in ChatContent
      // 
      // We retrieve the first step of the story
      // var firstStep = this.props.story.steps[this.props.story.startStepId];
      // // Set it as the current step
      // this.setState({
      //   currentStep: firstStep.messageId
      // });

      // // Render it
      // this.props.renderStep(firstStep);


      // V2 when resuming, render all resumed steps => more optimized
      const { steps } = this.props.story;
      const stepValues = Object.values(steps);

      for (let step of stepValues){
        if (!step.delay){
          step.init = true;
          if (step.ownerType === "input") {
            step = this.convertInputToMessage(step);
          } 
          this.props.renderStep(step);
        } else { 
          this.setState({
            currentStep: step.messageId
          }, () => this.props.renderStep(step));
          break;
        }
      }
    }

    // If there is no story loaded, and nothing loading
    // We just cleared the story, and we need to reload the story
    const storyWasCleared =
      this.props.story.id === undefined &&
      this.props.story.loading === false &&
      this.props.story.id !== prevProps.story.id;

    // If there is was a story, but the URL changed (via a link)
    // We need to reload the story
    const newStoryTriggered =
      prevProps.story.id !== undefined &&
      prevProps.story.id !== this.props.match.params.storyid &&
      prevProps.match.params.storyid !== this.props.match.params.storyid;

    if (storyWasCleared || newStoryTriggered) {
      this.props.getStory(
        this.props.game.urlkey,
        this.props.match.params.storyid
      );
    }
  }

  triggerNextStep(data) {
    var { currentStep } = this.state;
    var { steps } = this.props.story;

    // Cases:
    // Case 1: The bot trigger another bot message (via "trigger")
    // Case 2: The bot trigger user input
    // Case 3: The user sent a new input
    // Case 4: The user message triggered next step (via loading timer)

    // Case 1: botstep point to another step (data empty)
    // Case 1.b: We have an input step, already filled with user input
    // Case 2: botstep contains "input" key, trigger renderInput() data empty)
    // Case 3: The input from renderInput() triggered, data contains the user response.
    // => Call server to get next steps
    // => render the user message (with loading display)
    // Case 4: the user message (with loading display) triggers, either create a fake bot message (loading ON), or trigger the next bot message if it exists

    // Case 3
    if (steps[currentStep].ownerType === "input") {
      // Remove the input
      this.props.clearInput();

      // Create a messageID which will be triggered once the response is received
      const trigger = nanoid();

      // Simulate as if we called the server
      this.props.sendUserMessage(
        this.props.game.urlkey,
        this.props.story.id,
        steps[currentStep].stepId,
        this.props.story.matchId,
        trigger,
        data
      );

      // Create a fake messageID for the user message
      const messageId = nanoid();

      // Set the user answer inside the inputStep
      steps[currentStep].data = data;
      steps[currentStep].trigger = trigger;
      steps[currentStep].messageId = messageId;

      // Convert the input step and render it

      // We display the user choice as a message
      // Create user step
      let userStep = this.convertInputToMessage(steps[currentStep]);

      // Save the user step as the current step
      this.setState({
        currentStep: userStep.messageId
      });

      // Render the user step
      this.props.renderStep(userStep);
      return;
    }

    // Retrieve the next id triggered
    const trigger = steps[currentStep].trigger;

    // Case 4 and the chat didnt yet received chatbot messages
    if (
      steps[currentStep].ownerType === "user" &&
      steps[trigger] === undefined
    ) {
      // We need to create a fake bot step, which will be replaced once the server answered

      // Create fake step
      const fakeBotStep = {
        messageId: trigger,
        ownerType: "bot",
        ownerId: "bot1",
        fake: true,
        type: "text",
        content: ""
      };

      // Save the fake bot step as the current step
      this.setState({
        currentStep: fakeBotStep.messageId
      });

      // Render the fake bot step
      this.props.renderStep(fakeBotStep);
      return;
    }

    // Retrieve the next step in the flow
    let nextStep = steps[trigger];

    // Save the next step as the current step
    this.setState({
      currentStep: nextStep.messageId
    });

    // Case 2
    if (nextStep.ownerType === "input" && nextStep.data === undefined) {
      this.props.renderInput(nextStep);
    }
    // Case 1
    else {
      // Case 1.b
      if (nextStep.ownerType === "input") {
        nextStep = this.convertInputToMessage(nextStep);
      }

      // Render the next step
      this.props.renderStep(nextStep);
    }
  }

  convertInputToMessage(inputStep) {
    // clone the input step
    let userStep = Object.assign({}, inputStep);
    // Set the ownerType as "user"
    userStep.ownerType = "user";
    userStep.type = "text";

    /* @care: Quick fix. Should just use the ownerId inside the step, but to be retroactive, had to do that. Else every answer appears with a "bot1" ownerId*/
    userStep.ownerId = "user";

    // Set content according to the type of input, and the value.
    if (inputStep.type === "text") {
      userStep.content = inputStep.data;
    } else if (inputStep.type === "options" || inputStep.type === "popup") {
      if (inputStep.multiple) {
        // Build a MessageList component if several answers
        if (inputStep.data.length > 1) {
          var elements = [];
          _.forEach(inputStep.data, function(value, key) {
            elements.push({
              label: inputStep.options[value].label,
              html: inputStep.options[value].html
            });
          });
          userStep.type = "list";
          userStep.content = elements;
        }
        // If only one answer, just set a MessageText
        else {
          userStep.content = inputStep.data[0]
            ? inputStep.options[inputStep.data[0]].label
            : "";
          userStep.html = inputStep.options[inputStep.data[0]].html;
        }
      } else {
        userStep.content = inputStep.options[inputStep.data].label;
        userStep.html = inputStep.options[inputStep.data].html;
      }
    }
    return userStep;
  }

  render() {
    return (
      <GameContainer>
        <Loader active={this.props.story.loading}>Loading</Loader>
        <ChatHeader />
        <ChatContent />
        <ChatFooter />
      </GameContainer>
    );
  }
}

Chat.propTypes = {
  sendUserMessage: PropTypes.func.isRequired,
  renderStep: PropTypes.func.isRequired,
  renderInput: PropTypes.func.isRequired,
  clearInput: PropTypes.func.isRequired,
  story: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  game: state.game,
  story: state.story
});

export default connect(
  mapStateToProps,
  {
    getStory,
    sendUserMessage,
    renderStep,
    renderInput,
    clearInput
  }
)(Chat);
