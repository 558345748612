import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Dimmer, Message } from "semantic-ui-react";

import { clearMessage } from "actions/messageActions";

class MessageHandler extends Component {
  constructor(props) {
    super(props);

    this.dismissMessage = this.dismissMessage.bind(this);
  }

  dismissMessage() {
    this.props.clearMessage();
  }

  render() {
    const hasMessage =
      typeof this.props.message === "object" &&
      Object.keys(this.props.message).length !== 0;

    return (
      <Dimmer.Dimmable dimmed={hasMessage}>
        <Dimmer active={hasMessage} onClickOutside={this.dismissMessage}>
          {hasMessage ? (
            <Message className="error-message">
              {this.props.header ? (
                <Message.Header>{this.props.message.header}</Message.Header>
              ) : null}
              <p>{this.props.message.content}</p>
            </Message>
          ) : null}
        </Dimmer>
        {this.props.children}
      </Dimmer.Dimmable>
    );
  }
}

MessageHandler.propTypes = {
  message: PropTypes.object
};

const mapStateToProps = state => ({
  message: state.message
});

export default connect(
  mapStateToProps,
  { clearMessage }
)(MessageHandler);
