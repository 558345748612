import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import Avatar from "./Avatar";


import defaultConfiguration from "../DefaultConfiguration";
import MessageText from "./MessageText";
import MessageVideo from "./MessageVideo";
import MessageImage from "./MessageImage";
import MessageList from "./MessageList";

import EventBus, { EVENT_NEXT_STEP } from "util/EventBus";



class Message extends Component {
  constructor(props) {
    super(props);

    // Set the configuration of the message
    var configuration = this.getConfiguration();

    // Use the correct delay (props if defined, else configuration)
    this.delay = this.props.message.hasOwnProperty("delay")
      ? this.props.message.delay
      : configuration.delay;
    this.state = {
      loading: !!this.delay
    };

    this.triggerNextStep = this.triggerNextStep.bind(this);
  }

  componentDidMount() {
    // Remember the timer handle to remove it in unMount
    this.timerStart = new Date();

    if (!this.delay && !this.props.message.fake) {
      if (!this.props.message.init){
        this.triggerNextStep();
      }
    } else {
      this.timerHandler = setTimeout(() => {
        this.setState({ loading: false });
      }, this.delay);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // If the message is not a fake anymore and we are in "auto delay", we need to compare the current time consumed to the new delay of the message
    if (
      prevProps.message.fake &&
      prevProps.message.fake !== this.props.message.fake
    ) {
      let consumedDelay = new Date() - this.timerStart;
      if (this.timerHandler && this.delay > consumedDelay) {
        clearTimeout(this.timerHandler);
        this.setState({ loading: true });
        this.timerHandler = setTimeout(() => {
          this.setState({ loading: false });
        }, this.delay - consumedDelay);
      }
      return;
    }

    // Check state.loading or props.fake updated
    if (
      prevProps.message.fake !== this.props.message.fake ||
      prevState.loading !== this.state.loading
    ) {
      // If we are done loading and not a fake, we trigger next step
      if (!this.state.loading && !this.props.message.fake) {
        this.triggerNextStep();
      }
    }
  }

  componentWillUnmount() {
    // Is our timer running?
    if (this.timerHandler) {
      // Yes, clear it
      clearTimeout(this.timerHandler);
      this.timerHandler = null;
    }
  }

  triggerNextStep() {
    // We trigger the process of the next step
    EventBus.emit(EVENT_NEXT_STEP, null, "client");
  }

  getConfiguration() {
    const { ownerType, ownerId } = this.props.message;

    var configuration = _.merge({}, defaultConfiguration[ownerType]);

    if (
      this.props.configuration &&
      this.props.configuration[ownerType] &&
      this.props.configuration[ownerType][ownerId]
    ) {
      _.merge(configuration, this.props.configuration[ownerType][ownerId]);
    }

    return configuration;
  }

  renderAvatar(avatar) {
    const { alt, src, styleContainer, styleImage } = avatar;
    return (
      <Avatar
        alt={alt}
        src={src}
        styleContainer={styleContainer}
        styleImage={styleImage}
      />
    );
  }

  render() {
    var configuration = this.getConfiguration();

    const avatar = configuration.hasAvatar
      ? this.renderAvatar(configuration.avatar)
      : "";

    const sideClass = "chat-message-" + configuration.side;

    return (
      <div
        className={`chat-message-container ${sideClass}`}
        style={configuration.styleMessageContainer}
      >
        <div className="chat-message" style={configuration.styleMessage}>
          {avatar}
          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderContent() {
    var configuration = this.getConfiguration();
    const displayLoading = this.props.message.fake || this.state.loading;

    switch (this.props.message.type) {
      case "image":
        return (
          <MessageImage
            loading={displayLoading}
            content={this.props.message.content}
            style={configuration.styleMessageContent}
            styleText={configuration.styleMessageText}
            styleImage={configuration.styleMessageImage}
          />
        );
      case "video":
        return (
          <MessageVideo
            loading={displayLoading}
            content={this.props.message.content}
            style={configuration.styleMessageContent}
            styleText={configuration.styleMessageText}
            styleVideo={configuration.styleMessageVideo}
          />
        );
      case "list":
        return (
          <MessageList
            loading={displayLoading}
            content={this.props.message.content}
            style={configuration.styleMessageContent}
            styleText={configuration.styleMessageText}
          />
        );
      default:
        return (
          <MessageText
            html={this.props.message.html}
            loading={displayLoading}
            content={this.props.message.content}
            style={configuration.styleMessageContent}
            styleText={configuration.styleMessageText}
          />
        );
    }
  }
}

Message.propTypes = {
  message: PropTypes.shape({
    delay: PropTypes.number,
    content: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
      })
    ]).isRequired,
    type: PropTypes.string.isRequired,
    ownerType: PropTypes.string.isRequired,
    ownerId: PropTypes.string.isRequired,
    fake: PropTypes.bool
  }).isRequired
};

const mapStateToProps = state => ({
  configuration: state.story.configuration
});

export default connect(
  mapStateToProps,
  null
)(Message);
