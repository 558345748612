import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import nl2br from "react-nl2br";

import { withTranslation } from "react-i18next";
import i18next from "i18next";

import { Card, Label, Header, Image } from "semantic-ui-react";

import LogoutButton from "components/Common/LogoutButton";
import GameContainer from "components/App/GameContainer";
import Billboard from "components/App/Billboard";

import "./gameResume.css";

class GameResume extends Component {
  constructor(props) {
    super(props);
    this.renderStory = this.renderStory.bind(this);

    // Set language for the whome game (aka room)
    const lang = this.props.game.configuration.language || "fr";
    i18next.changeLanguage(lang);
  }

  renderSubHeader() {
    if (!this.props.game.configuration.resume) {
      return "";
    }

    // Handle image, if any
    var img;
    if (this.props.game.configuration.resume.subheader_img) {
      img = (
        <div className="game-resume-subheader-image-container">
          <img
            className="game-resume-subheader-image"
            src={this.props.game.configuration.resume.subheader_img}
            alt=""
          />
        </div>
      );
    }

    // Handle text, if any
    var text;
    if (this.props.game.configuration.resume.subheader_txt) {
      text = (
        <div className="game-resume-subheader-text">
          {nl2br(this.props.game.configuration.resume.subheader_txt)}
        </div>
      );
    }

    return (
      <div className="game-resume-subheader">
        {img}
        {text}
      </div>
    );
  }

  renderStory(story) {
    const game = this.props.game;
    const { id, code, name } = story;
    let disabled = false;

    // We need to process the status of the story.
    // Check in the this.props.matches
    // - If nothing there, the story isnt started yet
    // - If status=1: The story is Ongoing
    // - If status=2: The story is Completed
    var status = "";
    if (this.props.matches[id] === undefined) {
      status = "Non commencé";
    } else if (this.props.matches[id].status === 1) {
      status = "En cours";
    } else if (this.props.matches[id].status === 2) {
      status = "Terminé";
    }

    // Determine if the story must be disabled
    if (
      (game.configuration.specificStoriesOrder &&
        this.props.player.currentStoryId !== id) ||
      (!game.configuration.canReplayStories && status === "Terminé")
    ) {
      disabled = true;
    }

    // If the "specificStoriesOrder" option is enabled,
    // and the story is not the current story of the user, we disable it:

    // If disabled, we:
    // - Add CSS to make it look like disabled
    // - Remove the link as well
    if (disabled) {
      return (
        <Card key={id} fluid className="disabled">
          <Card.Content>
            <Label horizontal>{status}</Label>
            {name}
          </Card.Content>
        </Card>
      );
    }

    // Not disabled

    const url = `/${game.urlkey}/detail/${id}`;
    // const difficulty = story.difficulty !== undefined ? story.difficulty : (Math.round(Math.random() * 4) + 1);

    return (
      <div className="superslide" key={id}>
        <Card as={Link} to={url}>
          <Image
            src={`/images/${game.urlkey}/${code}-thumb.jpg`}
            wrapped
            ui={false}
          />
          <Card.Content>
            <Card.Header>
              <p className="superslide-title"> {name} </p>
            </Card.Header>
            {/* <Rating defaultRating={difficulty} maxRating={5} disabled /> */}
          </Card.Content>
        </Card>
      </div>
    );
  }

  render() {
    const baseUrl = `/${this.props.game.urlkey}/detail/`;
    const stories = Object.values(this.props.stories).sort(
      (s1, s2) => s1.rankOrder - s2.rankOrder
    );
    const billboardStory = stories.shift();

    const { t } = this.props;

    const headerStyle = {
      textTransform: "uppercase",
      flexGrow: "1",
      margin: "0",
      fontWeight: "400",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      paddingLeft: "20px",
      fontFamily: "'Open Sans', sans-serif",
    };

    return (
      <GameContainer>
        <div className="game-resume-header">
          <Header as="h3" textAlign="center" style={headerStyle}>
            {this.props.game.name}
          </Header>
          <LogoutButton />
        </div>
        {this.renderSubHeader()}
        <div className="billboard-header">
          <span className="billboard-header-text">{t("to_get_started")}</span>
        </div>
        {billboardStory && (
          <Billboard
            story={billboardStory}
            game={this.props.game}
            baseUrl={baseUrl}
          />
        )}
        {stories.length > 0 && (
          <React.Fragment>
            <div className="slider-header">
              <span className="slider-header-text">{t("other_games")}</span>
            </div>

            <div>
              <div
                className="superslider"
                style={{
                  width: "100%",
                  overflowX: "auto",
                  overflowY: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {stories.map(this.renderStory)}
              </div>
            </div>
          </React.Fragment>
        )}
      </GameContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  game: state.game,
  player: state.player,
  matches: state.matches,
  stories: state.stories,
});

export default connect(mapStateToProps, null)(withTranslation()(GameResume));
