import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";

import { withTranslation } from "react-i18next";

import { getStory } from "actions/gameActions";

import { Header, Rating, Icon, Button } from "semantic-ui-react";

import LogoutButton from "components/Common/LogoutButton";
import GameContainer from "components/App/GameContainer";
import Billboard from "components/App/Billboard";

import "./gameDetail.css";
import BackButton from "components/Common/BackButton";

class GameDetail extends Component {
  constructor(props) {
    super(props);

    this.props.getStory(this.props.game.urlkey, this.props.match.params.storyid);

    // Set language for the whome game (aka room)
    const lang = this.props.game.configuration.language || "fr";
    const {  i18n } = this.props; 
    i18n.changeLanguage(lang);
  }

  render() {
    const story = this.props.story;
    const t = this.props.t;
    const game = this.props.game;
    const baseUrl = "/" + this.props.match.params.gameurlkey + "/play/";

    let difficulty = story.difficulty ? story.difficulty : '';

    return (
      <GameContainer className="game-detail-container">
        <div className="game-detail-header-container">
          <BackButton href={"/" + game.urlkey + "/resume"} iconName="arrow left" />
          <Header className="game-detail-header" as="h3">
            {this.props.game.name}
          </Header>

          <LogoutButton />
        </div>
        <Billboard story={story} baseUrl={baseUrl} game={this.props.game} />
        {difficulty && (
          <div className="difficulty-container">
            <div className="difficulty-label">
              <span>{t("difficulty")}</span>
            </div>
            <div>{!story.loading && <Rating defaultRating={difficulty} maxRating={5} disabled />}</div>
          </div>
        )}
        <div className="game-detail-description">
          {story.description ? renderHTML(story.description) : "Découvrez vite ce scénario !"}
        </div>
        <div className="game-detail-call-to-action-container">
          <Button size="huge" color="red" as={Link} to={baseUrl + story.id}>
            <Icon name="play"></Icon>
            <span>{t("play")}</span>
          </Button>
        </div>
      </GameContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  game: state.game,
  story: state.story,
});

export default connect(mapStateToProps, { getStory })(withTranslation()(GameDetail));
