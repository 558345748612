import React, { Component } from "react";
import PropTypes from "prop-types";
import renderHTML from "react-render-html";

import _ from "lodash";

class OptionsField extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.renderOption = this.renderOption.bind(this);
  }

  onSubmit(data) {
    this.props.onSubmit(data);
  }

  renderOption(option) {
    const { value, label, html } = option;

    let style = _.merge({}, this.props.styleOptionElement);

    return (
      <li
        key={value}
        className="chat-input-option"
        style={this.props.styleOption}
      >
        <button
          style={style}
          className="chat-input-option-element"
          value={value}
          onClick={() => this.onSubmit(value)}
        >
          {html ? renderHTML(label) : label}
        </button>
      </li>
    );
  }

  render() {
    return (
      <div className="chat-input-options-container">
        <ul className="chat-input-options">
          {_.map(this.props.options, this.renderOption)}
        </ul>
      </div>
    );
  }
}

OptionsField.propTypes = {
  options: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  styleOption: PropTypes.object.isRequired,
  styleOptionElement: PropTypes.object.isRequired,
  styleOptionElementSelected: PropTypes.object.isRequired
};

export default OptionsField;
