import React, { Component } from "react";
import PropTypes from "prop-types";

import SubmitButton from "./SubmitButton";

class TextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: []
    };

    this.onKeyPress = this.onKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    // Set the focus inside the text field when it shows
    this.textInput.focus();
  }

  onKeyPress(e) {
    if (e.key === "Enter") {
      this.onSubmit();
      e.preventDefault();
    }
  }

  onChange(e) {
    this.setState({ data: e.target.value });
  }

  onSubmit() {
    this.props.onSubmit(this.state.data);
  }

  render() {
    return (
      <div>
        <div className="chat-input-text-container">
          <input
            type="text"
            className="chat-input-text"
            placeholder="Tapez votre réponse..."
            name={this.props.name}
            value={this.state.data}
            onKeyPress={this.onKeyPress}
            onChange={this.onChange}
            onBlur={this.onFocus}
            disabled={this.props.disabled}
            autoComplete="off"
            ref={el => {
              this.textInput = el;
            }}
          />
        </div>
        <SubmitButton onSubmit={this.onSubmit} />
      </div>
    );
  }
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.string
};

export default TextField;
