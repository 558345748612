import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import { Form, Button, Message } from "semantic-ui-react";

import { loginUser } from "actions/authActions";
import { clearFormError } from "actions/messageActions";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      password: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.props.clearFormError();
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.loginUser(
      this.props.gameId,
      this.state.login,
      this.state.password
    );
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.props;

    const formError =
      typeof this.props.errors === "object" &&
      Object.keys(this.props.errors).length !== 0;

    return (
      <React.Fragment>
        <Form onSubmit={this.onSubmit} error={formError}>
          {formError ? (
            <Message error>
              <Message.List>
                {_.map(errors, function (error, key) {
                  return <Message.Item key={key}>{error}</Message.Item>;
                })}
              </Message.List>
            </Message>
          ) : null}
          <Form.Input
            name="login"
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Email"
            value={this.state.login}
            onChange={this.onChange}
            autoComplete="off"
            error={!!errors.login}
          />
          <Form.Input
            className="login-password-input"
            name="password"
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Mot de passe"
            type="password"
            value={this.state.password}
            onChange={this.onChange}
            error={!!errors.password}>

          </Form.Input>

          <div className="game-home-login-forgot-link">
            <Link to={`/${this.props.gameURL}/forgot`}>
              Mot de passe oublié ?
          </Link>
          </div>

          <Button fluid size="large">
            Se connecter
        </Button>


        </Form>


        {/* <Divider /> */}
        <div className="not-already-registered">
          Vous n'êtes pas encore inscrit : <Link to={`/${this.props.gameURL}/register`}>s'inscrire</Link> !
        </div>
        {/* <Button basic fluid size="large" as={Link}  to={`/${this.props.gameURL}/register`}> S'inscrire</Button> */}
       </React.Fragment>

    );
  }
}

Login.propTypes = {
  gameId: PropTypes.number.isRequired,
  gameURL: PropTypes.string.isRequired,
  loginUser: PropTypes.func.isRequired,
  clearFormError: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.formErrors
});

export default connect(
  mapStateToProps,
  { clearFormError, loginUser }
)(Login);
