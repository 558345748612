import React, { Component } from "react";
import { connect } from "react-redux";
import Input from "./Input/Input";

class ChatFooter extends Component {
  render() {
    return (
      <div
        className="chat-footer"
        style={this.props.game.configuration.styleChatFooter}
      >
        <Input />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  game: state.game
});

export default connect(
  mapStateToProps,
  null
)(ChatFooter);
